import React from "react";
import styled from "styled-components";
import { useParams } from "react-router";
import Flex from "app/components/Flex";
import { H2, P1 } from "app/components/Typography";
import useGetProgramsV2 from "hooks/ProgramsV2/useGetProgramsV2";
import OutlineListComponent from "./OutlineListComponent";

const ProgramInfoContainer = styled(Flex)`
  ${({ theme }) => theme.mediaQueries.ltlg} {
    flex-direction: column;
  }
`;

const ProgramInfo = () => {
  const { slug } = useParams();
  const [programsV2Data] = useGetProgramsV2({
    variables: { slug },
  });

  const { content = {}, blocks = [] } = programsV2Data;
  const { competencies = {}, needs = {} } = content;
  const { title: competenciesTitle = "", skills = {} } = competencies || {};
  const { title: needsTitle = "", description: needsDescription = "" } = needs;
  const shouldOutlineBeVisible = blocks.length > 1;

  return (
    <ProgramInfoContainer width="100%">
      <Flex flexDirection="column" flex="45%">
        <Flex flexDirection="column" mb={4}>
          <H2 color="white" mb={4}>
            {competenciesTitle}
          </H2>
          {Object.keys(skills).length &&
            skills.map(skill => (
              <P1 color="monochrome.3" mb={3}>
                • {skill}
              </P1>
            ))}
        </Flex>
        <Flex flexDirection="column" mb={4}>
          <H2 color="white" mb={4}>
            {needsTitle}
          </H2>
          <P1 color="monochrome.3">{needsDescription}</P1>
        </Flex>
      </Flex>
      <Flex flex="10%" />
      {shouldOutlineBeVisible && (
        <Flex flexDirection="column" flex="45%" mb={3}>
          <H2 color="white" mb={4}>
            Program Outline
          </H2>
          {blocks.map(block => (
            <OutlineListComponent
              superscript={block?.superscript}
              title={block?.title}
            />
          ))}
        </Flex>
      )}
    </ProgramInfoContainer>
  );
};

export default ProgramInfo;
